
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        










































































.list {
  display: flex;
  flex-direction: column;
}

.list__link {
  justify-content: flex-start;
  white-space: pre-wrap;
}

.icon {
  flex-shrink: 0;
}
